export default {
  data() {
    return {
      list: {
        url: '/canteen/takeaway/getAllList',
        method: 'post',
        fields: this.getListFields(),
        action: false
      }
    };
  },
  methods: {
    getListFields() {
      return [
        {
          label: '姓名',
          prop: 'name',
          width: 120,
          headerAlign: 'center',
          align: 'center'
        },
        {
          label: '部门',
          prop: 'department',
          width: 160,
          headerAlign: 'center',
          align: 'center'
        },
        {
          label: '电话',
          prop: 'telephone',
          width: 160,
          headerAlign: 'center',
          align: 'center'
        },
        {
          label: '预约日期',
          prop: 'orderDate',
          width: 130,
          align: 'right',
          headerAlign: 'center'
        },
        {
          label: '领取时段',
          prop: 'category',
          width: 100,
          headerAlign: 'center',
          align: 'center',
          colors: ['', 'success', 'danger', 'warning'],
          formatter(row) {
            const values = ['', '早餐', '中餐', '晚餐'];
            return values[row.type];
          }
        },
        {
          label: '菜单',
          prop: 'menuList',
          minWidth: 230,
          showOverflowTooltip: true,
          headerAlign: 'center',
          align: 'left',
          formatter(row) {
            return row.menuList
              .map(item => `${item.name}*${item.nums}`)
              .join('、');
          }
        },
        {
          label: '应付金额',
          prop: 'shouldPrice',
          width: 120,
          align: 'right',
          headerAlign: 'center',
          color: 'danger',
          formatter(row) {
            return `￥${row.shouldPrice}`;
          }
        },
        {
          label: '优惠金额',
          prop: 'lowerPrice',
          width: 120,
          align: 'right',
          headerAlign: 'center',
          color: 'success',
          formatter(row) {
            return `￥${row.lowerPrice}`;
          }
        },
        {
          label: '实付金额',
          prop: 'price',
          width: 120,
          align: 'right',
          headerAlign: 'center',
          color: 'danger',
          formatter(row) {
            return `￥${row.price}`;
          }
        },
        {
          label: '状态',
          prop: 'status',
          width: 100,
          align: 'center',
          headerAlign: 'center',
          colors: ['', 'primary', 'success', 'info', 'success'],
          formatter(row) {
            const values = ['', '已预约', '已使用', '已取消', '已支付'];
            return values[row.status];
          }
        },
        {
          label: '支付状态',
          prop: 'payStatus',
          width: 100,
          align: 'center',
          headerAlign: 'center',
          colors: ['danger', 'success'],
          formatter(row) {
            const values = ['未支付', '已支付'];
            return values[row.payStatus];
          }
        },
        {
          label: '支付时间',
          prop: 'payTime',
          width: 220,
          headerAlign: 'center',
          align: 'center'
        }
      ];
    }
  }
};
